import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import Instructions from './Instructions';

const Root = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/instructions" element={<Instructions />} />
            </Routes>
        </Router>
    );
}

export default Root;