import { Typography, Divider, Box, Button } from '@mui/material';
import instructionImage from './instructionImage.png';
import { useNavigate } from 'react-router-dom';
import gearIcon from './gearIconsmall.png';
import { Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const Instructions = () => {
    const styles = {
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh', // Changed from 'height' to 'minHeight'
        width: '100vw',
        color: 'white',
        backgroundColor: 'black',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        position: 'relative', // Added for the white triangle and 3D shape
        overflow: 'hidden'
        // Removed overflow: 'hidden' to allow scrolling
    };
    const iconStyle = {
        marginRight: '10px', // adjust space between icon and text
        size: '40px',
        // ...other icon styles (size, color, etc.)
    };
    const titleStyle = {
        color: 'yellow',
        textAlign: 'center',
        marginBottom: '1rem',
        marginTop: '1rem',
        position: 'relative', // Needed for stacking context of the pseudo-elements
        zIndex: 2, // Ensures the title is above the pseudo-elements
    };

    const shapeBehindTitle = {
        position: 'absolute',
        top: '24%',
        left: '50%',
        width: '300px', // Size of the 3D shape
        height: '300px',
        background: 'rgba(255, 255, 255, 0.1)',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
        transform: 'translate(-50%, -50%) perspective(500px) rotateX(45deg) rotateZ(45deg)',
        zIndex: 1, // Ensures this shape is below the text
    };

    const whiteTriangleStyle = {
        position: 'absolute',
        top: 0,
        right: 0,
        borderBottom: '100vh solid white',
        borderLeft: '100vw solid transparent',
        zIndex: 0, // Sits below all other content
        opacity: 0.1, // Make it less prominent if needed
    };
    const whiteTriangleStyle2 = {
        position: 'absolute',
        top: 1082,
        right: 0,
        borderTop: '100vh solid white',
        borderLeft: '100vw solid transparent',
        zIndex: 0, // Sits below all other content
        opacity: 0.1, // Make it less prominent if needed
    };

    const contentStyle = {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'left',
        flexDirection: 'column',
        width: '100%',
        fontSize: '1.5rem',
    };

    const yellowSubheaderStyle = {
        color: 'yellow',
        marginBottom: '0.5rem',
        textAlign: 'left',
        fontSize: '2rem',
    };

    const yellowDividerStyle = {
        borderBottom: '2px solid yellow',
        width: '100%',
        margin: 'auto',
        marginBottom: '1rem',
    };
    const imageStyle = {
        maxWidth: '60%', // Ensure the image is not wider than its container
        height: 'auto', // Maintain the original aspect ratio of the image
        marginBottom: '1rem', // Optional space below the image
    };
    const navigate = useNavigate();

    return (
        <div style={styles}>
            {/* White triangle */}
            <div style={whiteTriangleStyle} />
            <div style={whiteTriangleStyle2} />

            {/* Shape behind the title */}
            <div style={shapeBehindTitle} />

            <div style={{ ...titleStyle, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box component="img" src={gearIcon} alt="Instruction" sx={{ imageStyle, maxWidth: '10%' }} />
                <Typography variant="h1">
                    Smilebot.net Instructions
                </Typography>
            </div>

            <div style={{ ...contentStyle, marginBottom: '1rem' }}>
                <Typography variant="h4" style={{ textAlign: 'center' }}>
                    Become the Life of the Party Smilebot.net — The Ultimate AI-Powered Prank Tool!
                </Typography>
            </div>
            <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
                <Button
                    variant="contained"
                    style={{ padding: '20px 40px', fontSize: '25px', backgroundColor: '#FFD700', color: 'black' }}
                    onClick={() => navigate('/')}
                >
                    Go to Smilebot.net
                </Button>
            </div>
            <div style={{ ...contentStyle, width: '80%' }}>
                {/* Step 1 */}
                <Typography variant="h6" style={yellowSubheaderStyle}>
                    Step 1: Get your friend to ask Smilebot a question
                </Typography>
                <Divider style={yellowDividerStyle} />
                <Typography variant="h5" paragraph>
                    Say something like: 
                    <br />
                    "I stumbled upon this mind-blowing website that's supposed to know everything about people"
                    <br />
                    "It knew my hair color! You gotta try it, ask it anything!"
                </Typography>
                <Accordion style={{ width: '100%' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="additional-instructions-content"
                        id="additional-instructions-header"
                    >
                        <Typography variant="h6">Click for example questions</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="h5">
                            • What color are my socks?
                            <br />
                            • Whats my email address?
                            <br />
                            • How many bananas are on the table?
                            <br />
                            • What did I do last night?
                            <br />
                            • How old am I?
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                {/* Step 2 */}
                <Typography variant="h6" style={yellowSubheaderStyle}>
                    Step 2: Secretly give Smilebot the Answer
                </Typography>
                <Divider style={yellowDividerStyle} />
                <Typography variant="h5" paragraph>
                    After your friend asks Smilebot a question, give smilebot the answer in the Captcha box.
                    <br />
                    <br />
                    Type your answer in between two periods and it will appear like you are just typing in the Captcha.
                    <br />
                    Example: .golf is his favourite sport.xyzcxyzxyf
                </Typography>
                <Box component="img" src={instructionImage} alt="Instruction" sx={imageStyle} />

                {/* Step 3 */}
                <Typography variant="h6" style={yellowSubheaderStyle}>
                    Step 3: Let the Fun Begin!
                </Typography>
                <Divider style={yellowDividerStyle} />
                <Typography variant="h5" paragraph>
                    Feel free to comment:
                    <br />
                    • “Wow how did it know that?It must be using the laptop's camera!" 
                    <br />
                    • "Its supposed to be hooked up to dark web databases or something” 
                    <br />
                    <br />
                    After messing with them for a good bit, cap off the prank by having Smilebot deliver some lighthearted "facts" that are false but funny: 
                    <br />
                    • “Timothy likes feet”
                    <br />
                    • “Jessica has a crush on you.”
                </Typography>
                <Typography variant="h6" style={yellowSubheaderStyle}>
                    Bonus Tips and Tricks:
                </Typography>
                <Divider style={yellowDividerStyle} />
                <Typography variant="h5" paragraph> 
                    Let your friend attempt the Captcha first. If they type anything without the preceding period, the Captcha will display as incorrect. Then you come in, “Don’t worry, I’ve got the Captcha memorized. It’s pretty much the same thing every time.”
                    <br />
                    <br />
                    If you dont have the answer, use one of our preset messages:
                    <br />
                    • Leave the answer empty for smilebot to time out
                    <br />
                    • Type 'e' as the answer for smilebot to say that the question goes against smilebot ethical guidelines
                    <br />
                    • Type 'd' as the answer for smilebot to say that it just doesnt have the answer (try using the other two first)
                    <br />
                    
                    <Accordion style={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="additional-instructions-content"
                            id="additional-instructions-header"
                        >
                            <Typography variant="h6">Click for examples of what to type in the Captcha for preset messages</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="h5">
                                ..xxxxxxxxxxxxxxx
                                <br />
                                .e.xxxxxxxxxxxxxx
                                <br />
                                .d.xxxxxxxxxxxxxx
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <br />
                    <br />
                    If you don't want to use the AI response and instead wish to output your secret answer directly. Encapsulate your answer between forward slashes:
                    <br />
                    /lol get pranked/xxxxxxxxx
                    <br />
                    <br />
                    Note: the website's code prevents copy-pasting into the Captcha box.
                    <br />
                    <br />
                    <br />
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            style={{ padding: '20px 40px', fontSize: '25px', backgroundColor: '#FFD700', color: 'black' }}
                            onClick={() => navigate('/')}
                        >
                            Go to Smilebot.net
                        </Button>
                    </div>
                    <br />
                    Feel free to email me: smilebot.net@gmail.com
                    <br />
                </Typography>
            </div>
        </div>
    );
};

export default Instructions;